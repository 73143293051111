<template>
  <div class="not-found-view">
    <h1>Error: 404</h1>
    <h2>Page Not Found</h2>
    <router-link class="link" :to="{name:welcomeRouteName}">click to restart</router-link>
  </div>
</template>

<script>
import TrackingMixin from '../mixins/TrackingMixin'
import { ROUTER_NAMES } from '../settings'
export default {
  mixins:[TrackingMixin],
  data(){
    return {
      welcomeRouteName:ROUTER_NAMES.WELCOME
    }
  },
  mounted(){
    this.douglasTrack()
  }
}
</script>

<style lang="stylus" scoped>
.not-found-view
  position absolute
  top 50%
  left 50%
  transform translate(-50%,-50%)
  text-align center
  color #bbb
  .link
    text-decoration underline
</style>